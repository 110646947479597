.login-page-outer {
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 1vh;
    padding-bottom: 2vh;
    box-shadow: 0px 0px 5px #fff;
    border-radius: 20px;
    
}

.login-page-header {
    margin-bottom: 3vh;
}

.login-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.login-page-textbox {
    margin-top: 10px;
    text-align: center;
}

.create-account-button {
    background-color: transparent;
    border: none;
    color:white;
}

p {
    font-size: 28px;
    color: red;
}

label {
    text-align: center;
    font-size: 30px;
    padding: 0.25rem 0;
}

input {
    margin: 0.5rem 0;
    padding: 1rem;
    border: none;
    border-radius: 10px;
}