@font-face {
    font-family: 'Dosis';
    src: local('Dosis'), url(../Fonts/Dosis.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }


.App {
    display: flex;
    font-family: 'Dosis';
    font-size: calc(14px);
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh);
}

#bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    transform: scale(1.0); 
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}