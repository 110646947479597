
.character-img {
    -webkit-filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.5));
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #4719e000;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #5639b83b;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }