#scroll-container {
    overflow: hidden;
  }
  
  #scroll-text {
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  
    /* animation properties */
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    
    -moz-animation: my-animation linear infinite;
    -webkit-animation: my-animation linear infinite;
    animation: my-animation linear infinite;
  }
  
  /* for Firefox */
  @-moz-keyframes my-animation {
    from { -moz-transform: translateX(-50%); }
    to { -moz-transform: translateX(50%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(-50%); }
    to { -webkit-transform: translateX(50%); }
  }
  
  @keyframes my-animation {
    0% {
      -moz-transform: translateX(0%);
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
    10% {
        -moz-transform: translateX(0%);
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
      }
    40% {
        -moz-transform: translateX(-100%) translateX(+130px);
      -webkit-transform: translateX(-100%) translateX(+130px);
      transform: translateX(-100%) translateX(+130px);
      }
    50% {
      -moz-transform: translateX(-100%) translateX(+130px);
      -webkit-transform: translateX(-100%) translateX(+130px);
      transform: translateX(-100%) translateX(+130px);
    }
    60% {
        -moz-transform: translateX(-100%) translateX(+130px);
      -webkit-transform: translateX(-100%) translateX(+130px);
      transform: translateX(-100%) translateX(+130px);
      }
    90% {
        -moz-transform: translateX(0%);
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
      }
    100% {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    }
  }