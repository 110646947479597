.create-character-outer {
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    box-shadow: 0px 0px 5px #fff;
    border-radius: 20px;
}

.create-character-header {
    margin-bottom: 4vh;
}

.create-character-list {
    align-items: left;
}

.create-character-label {
    text-align: left;
    font-size: 20px;
    color: lightgray;
}

.char-container {
    align-items: center;
    text-align: center;
    justify-content: center;
    justify-items: center;
    display:inline-block;
    position:relative;
    width:80px;
    height:80px;
    flex-direction: row;
}

.cc-selector {
    max-width: min(70vw, 600px);
}

.cc-selector input{
    margin:0;padding:0;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
}
.goku {
    background-image: url(../Icons/Outfits/goku.png);
}
.vegeta {
    background-image: url(../Icons/Outfits/vegeta.png);
}
.trunks {
    background-image: url(../Icons/Outfits/trunks.png);
}
.piccolo {
    background-image: url(../Icons/Outfits/piccolo.png);
}
.yamcha {
    background-image: url(../Icons/Outfits/yamcha.png);
}
.freezer {
    background-image: url(../Icons/Outfits/freezer.png);
}
.gohan {
    background-image: url(../Icons/Outfits/gohan.png);
}
.videl {
    background-image: url(../Icons/Outfits/videl.png);
}
.cooler {
    background-image: url(../Icons/Outfits/cooler.png);
}
.dende {
    background-image: url(../Icons/Outfits/dende.png);
}
.bebi {
    background-image: url(../Icons/Outfits/bebi.png);
}
.brolly {
    background-image: url(../Icons/Outfits/brolly.png);
}
.janemba {
    background-image: url(../Icons/Outfits/janemba.png);
}
.buu {
    background-image: url(../Icons/Outfits/buu.png);
}
.cell {
    background-image: url(../Icons/Outfits/cell.png);
}
.tenshin {
    background-image: url(../Icons/Outfits/tenshin.png);
}

.male {
    background-image: url(../Icons/male.png);
    max-width: 70px;
}

.female {
    background-image: url(../Icons/female.png);
    max-width: 70px;
}


.cc-selector input:active +.drinkcard-cc{opacity: .9;}
.cc-selector input:checked +.drinkcard-cc{
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
}
.drinkcard-cc{
    cursor:pointer;
    background-size:contain;
    background-repeat:no-repeat;
    display:inline-block;
    width:70px;height:70px;
    -webkit-transition: all 100ms ease-in;
       -moz-transition: all 100ms ease-in;
            transition: all 100ms ease-in;
    -webkit-filter: brightness(1.5) grayscale(1) opacity(.7) drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.5));
       -moz-filter: brightness(1.5) grayscale(1) opacity(.7) drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.5));
            filter: brightness(1.5) grayscale(1) opacity(.7) drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.5));
}
.drinkcard-cc:hover{
    -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9) drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.5));
       -moz-filter: brightness(1.2) grayscale(.5) opacity(.9) drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.5));
            filter: brightness(1.2) grayscale(.5) opacity(.9) drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.5));
}
