
.logo-img {
    height: 40vmin;
    min-height:10vmin;
}

.start-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


